import React, { createContext, useContext, useEffect, useState } from "react";
import API from "../utils/api";
import { toast } from "react-toastify";

const FinancialDataContext = createContext();

export const FinancialDataProvider = ({ children }) => {
  const [financialData, setFinancialData] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleError = (error) => {
    toast.error("An error occurred while processing your request");
    if (error.response) {
      console.log("API error", error.response.data);
    } else {
      console.error("API error", error);
    }
  };

  const userId = JSON.parse(localStorage.getItem("userInfo"))
    ? JSON.parse(localStorage.getItem("userInfo"))._id
    : null;

  const fetchFinancialData = async () => {
    if (!userId) {
      return;
    }

    try {
      setLoading(true);
      const response = await API.getClientFinanceData(userId);
      if (response.status === 200) {
        setLoading(false);
        // Success 🎉
        console.log("Financial data response", response);
        setFinancialData(response.data);
      }
    } catch (error) {
      // Error 😨
      toast.info("An Error occurred while fetching financial data", {
        position: "bottom-left",
        autoClose: 1000,
        hideProgressBar: true,
      });
      if (error.response) {
        console.log("Financial data error", error.response.data);
        toast.info("An Error occurred while fetching financial data", {
          position: "bottom-left",
          autoClose: 1000,
          hideProgressBar: true,
        });
      } else if (error.request) {
        console.log("Financial data error", error.request);
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log("Financial data error", error.message);
      }
      console.error(error);
    }
  };

  const handleDeposit = async (amount) => {
    if (!userId) {
      toast.error("User not authenticated");
      return;
    }
    try {
      const response = await API.makeDeposit(userId, amount);
      if (response.status === 200) {
        toast.success("Deposit successful!");
        fetchFinancialData();
      }
    } catch (error) {
      handleError(error);
    }
  };

  const handleWithdrawal = async (amount) => {
    if (!userId) {
      toast.error("User not authenticated");
      return;
    }
    try {
      const response = await API.makeWithdrawal(userId, amount);
      if (response.status === 200) {
        toast.success("Withdrawal successful!");
        fetchFinancialData();
      }
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchFinancialData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return (
    <FinancialDataContext.Provider
      value={{
        financialData,
        setFinancialData,
        loading,
        setLoading,
        handleDeposit,
        handleWithdrawal,
      }}
    >
      {children}
    </FinancialDataContext.Provider>
  );
};

export const FinancialDataState = () => {
  return useContext(FinancialDataContext);
};
