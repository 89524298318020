import React, { useState,useEffect } from "react";
import {
  Flex,
  Heading,
  Input,
  Button,
  InputGroup,
  FormErrorMessage,
  Stack,
  InputLeftElement,
  chakra,
  Box,
  useToast,
  Link,
  Avatar,
  FormControl,
  InputRightElement,
} from "@chakra-ui/react";
import { FaLock, FaUserAlt } from "react-icons/fa";
import { ImagetoBase64 } from "../../utils/ImagetoBase64";
import loginSignupImage from "../../assets/login-animation.gif";
import { MdMarkEmailRead } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { registerUser, useAuthDispatch, useAuthState } from "../../context";
import { useForm } from "../../utils/useForm";

const CFaUserAlt = chakra(FaUserAlt);
const CFaLock = chakra(FaLock);
const CMdMarkEmailRead = chakra(MdMarkEmailRead);
//const CFaPhoneAltock = chakra(FaPhoneAlt);

const SignUp = () => {
  const dispatch = useAuthDispatch();
  const { loading } = useAuthState();
  const toast = useToast();
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleShowClick = () => setShowPassword(!showPassword);

  const [avatar, setAvatar] = useState(null);

  const handleAvatarChange = async(e)=>{
    const file = await ImagetoBase64(e.target.files[0])
    setAvatar(file);
    
  };
  

  const {
    handleSubmit,
    handleChange,
    data: user,
    errors,
  } = useForm({
    validations: {
   
      username: {
        custom: {
          isValid: (value) => value.length > 1,
          message: "The username needs to be at least 6 characters long.",
        },
        pattern: {
          value: "^[A-Za-z]*$",
          message:
            "You're not allowed to use special characters or numbers in your name.",
        },
      },
      email: {
        pattern: {
          value: "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.]+$",
          message: "Please enter a valid email address",
        },
      },
      password: {
        custom: {
          isValid: (value) => {
            // Check if the password is at least 6 characters long
            if (value.length < 6) {
              return false;
            }
      
            // Check if the password contains at least one letter
            if (!/[a-zA-Z]/.test(value)) {
              return false;
            }
      
            // Check if the password contains at least one number
            if (!/\d/.test(value)) {
              return false;
            }
      
            // Check if the password contains at least one symbol
            // eslint-disable-next-line no-useless-escape
            if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(value)) {
              return false;
            }
      
            // If all conditions pass, the password is valid
            return true;
          },
          message: "The password must be at least 6 characters long and contain at least one letter, one number, and one symbol.",
        },
      },      
    },

    onSubmit: async (e) => {
      try {
        const plan = new URLSearchParams(window.location.search).get("plan"); // Get the plan from the query parameter
        console.log("Selected Plan:", plan); 
        const payload = {
            username: user.username,
            email: user.email,
            password: user.password,
            plan: plan,
        };

        let response = await registerUser(dispatch, payload); //registerUser action makes the request and handles all the neccessary state changes
        console.log(response);
        if (response){
          toast({
            title: "Registration Successful",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "bottom",
          });
          localStorage.setItem("user", JSON.stringify(response));
          navigate("/login");
        }

       
      } catch (error) {
        // Object.keys(error.response).forEach(function (prop) {
        //   // `prop` is the property name
        //   // `data[prop]` is the property value
        //   return error.response[prop][0][0];
        // });
        alert("Error Occurred");

        toast({
          title: "Error Occured!",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "bottom",
        });
      }
    },
  });

  useEffect(() => {
    const plan = new URLSearchParams(window.location.search).get("plan");
    if (plan) {
   
    }
  }, []);
  const handleEnterKey = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  

  return (
    <Flex
      flexDirection="column"
      width="100wh"
      height="100vh"
      backgroundColor="white"
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        flexDir="column"
        mb="2"
        justifyContent="center"
        alignItems="center"
      >
          <label htmlFor="avatar-upload">
          <Avatar
            as="button"
            cursor="pointer"
            src={avatar ? avatar: loginSignupImage}
            onClick={() => document.getElementById('avatar-upload').click()}
            _hover={{
              "&:before": {
                content: '"Upload"',
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                textAlign: "center",
                color: "#fe6600",
                fontSize: "sm",
                fontWeight: "bold",
                zIndex: 1,
              },
            }}
            _focus={{
              "&:before": {
                content: '"Upload"',
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                textAlign: "center",
                color: "#fe6600",
                fontSize: "sm",
                fontWeight: "bold",
                zIndex: 1,
              },
            }}
          />
        </label>
        <Heading color="#222222" fontSize={"20px"} lineHeight={1.25} pb={4}>
          Create your account
        </Heading>
        <form id="login-form" onSubmit={handleSubmit}>
          <Box minW={{ base: "90%", md: "468px" }}>
            <Stack
              spacing={4}
              p="1rem"
              backgroundColor="whiteAlpha.900"
              boxShadow="md"
            >
               <FormControl id="avatar" display="none">
                <Input
                  type="file"
                  accept="image/*"
                  onChange={handleAvatarChange}
                  id="avatar-upload"
                />
              </FormControl>
              <FormControl id="username" isRequired isInvalid={errors.username}>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<CFaUserAlt color="#fe6600" />}
                  />
                  <Input
                    id="username"
                    type="text"
                    value={user.username || ""}
                    onChange={handleChange("username")}
                    textAlign={"start"}
                    placeholder="Username"
                  />
                </InputGroup>
                <FormErrorMessage>{errors.username}</FormErrorMessage>
              </FormControl>
              <FormControl id="email" isInvalid={errors.email}>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<CMdMarkEmailRead color="#fe6600" />}
                  />
                  <Input
                    id="email"
                    textAlign={"start"}
                    type="email"
                    placeholder="email address"
                    value={user.email || ""}
                    onChange={handleChange("email")}
                  />
                </InputGroup>
                <FormErrorMessage>{errors.email}</FormErrorMessage>
              </FormControl>
              <FormControl id="password" isRequired isInvalid={errors.password}>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    color="gray.300"
                    children={<CFaLock color="#fe6600" />}
                  />
                  <Input
                    id="password"
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    textAlign={"start"}
                    value={user.password || ""}
                    onChange={handleChange("password")}
                    onKeyDown={(e) => handleEnterKey(e)}
                  />
                  <InputRightElement width="4.5rem">
                    <Button h="1.75rem" size="sm" onClick={handleShowClick}>
                      {showPassword ? "Hide" : "Show"}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>{errors.password}</FormErrorMessage>
              </FormControl>
              <Button
                borderRadius={0}
                textColor={"white"}
                variant="solid"
                bgColor="#fe6600"
                width="full"
                isDisabled={!user.username || !user.email || !user.password}
                bg={
                  !user.username || !user.password || !user.email
                    ? "brand.200"
                    : "brand.300"
                }
                color={
                  !user.password || !user.username || !user.email
                    ? "brand.300"
                    : "brand.200"
                }
                _focus={{ outline: "none" }}
                _active={{ outline: "none" }}
                isLoading={loading}
                onClick={handleSubmit}
              >
                Register
              </Button>
            </Stack>
          </Box>
        </form>
      </Stack>
      <Box>
        Already a member ?{" "}
        <Link
          color="#fe6600"
          href="#"
          onClick={() => {
            navigate("/login");
          }}
        >
          Login
        </Link>
      </Box>
    </Flex>
  );
};

export default SignUp;
