import React, { useState } from "react";
import {
  Box,
  Divider,
  VStack,
  Text,
  FormControl,
  Select,
  Input,
  FormLabel,
  Button,
} from "@chakra-ui/react";
import {
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";
import API from "../../utils/api";

const Withdrawal = () => {
  const [withdrawalAmount, setWithdrawalAmount] = useState(0);
  const [withdrawalMethod, SetWithdrawalMethod] = useState("");
  const [usdtWalletAddress, setUsdtWalletAddress] = useState("");
  const [label, setLabel] = useState("");

  const handleAmountChange = (value) => {
    setWithdrawalAmount(value);
  };

  const handlePreferredMethodChange = (event) => {
    SetWithdrawalMethod(event.target.value);
  };

  const handleUsdtWalletAddressChange = (event) => {
    setUsdtWalletAddress(event.target.value);
  };

  const handleWithdrawal = async (userId, amount, method) => {
    try {
     
      const response =  await API.makeWithdrawal(userId, amount, method);
      if (response.status === 200) {
        setLabel('Withdrawal request has been received. Please wait for confirmation.');
      } else {
        setLabel('Withdrawal request failed.');
      }
    } catch (error) {
      console.error('An error occurred:', error);
      setLabel('An error occurred while processing your request.');
    }
  };

  const handleWithdrawalRequest = () => {
    if (!withdrawalMethod || !withdrawalAmount || !usdtWalletAddress) {
      setLabel("All fields are required.");
    } else {
      const userId = JSON.parse(localStorage.getItem("userInfo")) ? JSON.parse(localStorage.getItem("userInfo"))._id : null;
      if (userId) {
        handleWithdrawal(userId, withdrawalAmount, withdrawalMethod);
      } else {
        setLabel("User information not available.");
      }
    }
  };

  return (
    <Box boxShadow={"md"} borderWidth={"1px"} m={4} w={"100"} >
      <VStack alignItems={"flex-start"} p={4}>
        <Text fontSize={"2xl"} fontWeight={"normal"}>
          Request Withdrawal
        </Text>
        <Divider />
        <Text textColor={'green'}>{label}</Text>
        <Text fontSize={"xl"} pt={4}>
          Withdrawable Amount
        </Text>
        <Box pt={4}>
          <FormControl id="Amount" isRequired>
            <FormLabel>Amount [USD]</FormLabel>
            <NumberInput
              defaultValue={0}
              value={withdrawalAmount}
              onChange={handleAmountChange}
            >
              <NumberInputField width={"70vw"} />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </FormControl>
        </Box>
        <Box pt={10}>
          <FormControl id="preferredmethod" isRequired>
            <FormLabel>Select Preferred Withdrawal Method </FormLabel>
            <Select
              placeholder="Select Preferred Withdrawal Method "
              width={"70vw"}
              value={withdrawalMethod}
              onChange={handlePreferredMethodChange}
            >
              <option value="Bitcoin">Bitcoin</option>
              <option value="Ethereum">Ethereum</option>
              <option value="Perfect Money">Perfect Money</option>
              <option value="USDT">USDT</option>
              <option value="REINVESTMENT">REINVESTMENT</option>
            </Select>
          </FormControl>
        </Box>
        <Box pt={10}>
          <FormControl id="preferredmethod" isRequired pb={4}>
            <FormLabel>USDT Wallet Address</FormLabel>
            <Input
              width={"70vw"}
              placeholder={"Enter Wallet Address"}
              mr={2}
              value={usdtWalletAddress}
              onChange={handleUsdtWalletAddressChange}
            />
          </FormControl>
        </Box>
        <Button
          alignSelf={"flex-end"}
          colorScheme={"blue"}
          onClick={handleWithdrawalRequest}
        >
          Request Withdrawal
        </Button>
      </VStack>
    </Box>
  );
};

export default Withdrawal;
