import React, { useEffect, useState } from 'react';
import {
  Box,
  Text,
  Stack,
} from "@chakra-ui/react";
import API from "../../../utils/api";

const Messages = () => {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await API.getMessage();
        setMessages(response.data);
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    };

    fetchMessages();

  }, []);

  return (
    <Box p={4}>
      <Stack spacing={4}>
        {messages.map((message) => (
          <Box
            key={message.id}
            borderWidth="1px"
            borderRadius="lg"
            p={4}
            boxShadow="md"
          >
            <Text fontSize="md" fontWeight="bold">Subject: {message.subject}</Text>
            <Text fontSize="sm" color="gray.600">Email: {message.email}</Text>
            <Text fontSize="sm" color="gray.600">Phone Number: {message.phonenumber}</Text>
            <Text fontSize="sm" color="gray.600">Name: {message.name}</Text>
            <Text fontSize="md" color="gray.600" >Message: {message.message}</Text>
          </Box>
        ))}
      </Stack>
    </Box>
  );
}

export default Messages;
