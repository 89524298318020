import React from 'react';
import { Box, SimpleGrid, Icon, Text, Stack, Flex, Heading } from '@chakra-ui/react';
import { RiLuggageDepositLine } from 'react-icons/ri';
import { MdManageAccounts } from 'react-icons/md';
import { BiMoneyWithdraw } from 'react-icons/bi';

const Feature = ({ title, text, icon }) => {
  return (
    <Stack  maxW={{ base: 'full', md: '300px' }}
    w={'full'}
    bgColor={'white'}
    p={5}
    mb={{ base: '4', md: 20 }}>
      <Flex
        fontWeight={900}
        fontSize={{ base: '2em', md: '3em' }}
        w={{ base: 20, md: 30 }}
        h={{ base: 20, md: 30 }}
        align={'center'}
        justify={'center'}
        color={'#fe6600'}
        mb={1}>
        {icon}
      </Flex>

      <Text
       fontWeight={700}
       fontSize={{ base: '14px', md: '16px' }}
        textAlign={'start'}
        lineHeight={{ base: '36px', md: '40px' }}
        marginLeft={{ base: '20px', md: '0px' }}
       >
        {title}
      </Text>
      <Text
        fontWeight={400}
        fontFamily={'sans-serif'}
        marginLeft={{ base: '20px', md: '0px' }}
        textAlign={'start'}
        color={'#3B3B3B'}
        lineHeight={{ base: '24px', md: '28px' }}
        fontSize={{ base: '14px', md: 'sm' }}>
        {text}
      </Text>
    </Stack>
  );
};

const HowItWorks = () => {
  return (
    <Box pl={{ base: 2, md: 10 }} pr={{ base: 2, md: 10 }} pt={20} bgColor={'#f9f9f9'}>
      <Heading
        lineHeight={{ base: 1.2, md: 1.42 }}
        fontFamily={'sans-serif'}
        fontSize={{ base: '26px', md: '36px' }}
        fontWeight={600}
        pb={10}
        color={'#2d2d2d'}
        
        textAlign={{ base: 'center', md: 'center' }}>
        How it works
      </Heading>
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={{ base: 1, md: 10 }}>
        <Feature
          icon={<Icon as={MdManageAccounts} />}
          title={'CREATE YOUR ACCOUNT'}
          text={'Register a free account and start your first step to passive income.'}
        />
        <Feature
          icon={<Icon as={RiLuggageDepositLine} w={10} h={10} />}
          title={'MAKE YOUR DEPOSIT'}
          text={'To start a growing capital, you need to make a deposit. You can do this from the deposit section of your account.'}
        />
        <Feature
          icon={<Icon as={BiMoneyWithdraw} w={10} h={10} />}
          title={'WITHDRAW PROFITS'}
          text={'It only takes a few seconds to initiate your withdrawals and have your earnings paid to you. All our withdrawals are instant.'}
        />
      </SimpleGrid>
    </Box>
  );
};

export default HowItWorks;
