import {
  Box,
  Flex,
  Icon,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { StatusData } from "../../../constants/StatusData";

const Dashboard = () => {
  return (
    <Box>
      <Flex direction={{ base: "column", md: "row" }} h={{ base: "auto", md: "40vh" }}>
          <SimpleGrid columns={{ base: 1, md: 4 }} spacing="10px">
            {StatusData.map((option, index) => {
              return (
                <Flex
                alignItems={'flex-start'}
                  width="90%"
                  borderWidth={1}
                  height="20vh"
                  shadow="md"
                  borderRadius="5px"
                  color="#555555"
                  key={index}
                  p={10}
                 
                >
                  <Icon h={30} w={30} as={option.icon} />
                  <VStack marginLeft={20} >
                    <Stat>
                      <StatLabel color={'#747474'} fontSize={'15px'} textColor={'#747474'} fontWeight={400} lineHeight={'28px'}fontFamily ={'sans-serif'}>{option.title} </StatLabel>
                      <StatNumber>{option.count}</StatNumber>
                    </Stat>
                  </VStack>
                </Flex>
              );
            })}
          </SimpleGrid>
      </Flex>
    </Box>
  );
};

export default Dashboard;
