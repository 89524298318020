import React, { useState } from "react";
import {
  Box,
  VStack,
  FormControl,
  useToast,
  Input,
  Heading,
  Textarea,
  Button,
  FormErrorMessage,
  Stack,
  Flex,
} from "@chakra-ui/react";
//import images from "../../constants/images";
import { MdEmail, MdLocationOn } from "react-icons/md";
import API from "../../utils/api";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phonenumber, setPhoneNumber] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [subjectError, setSubjectError] = useState("");
  const [messageError, setMessageError] = useState("");
  const toast = useToast();
  const [charCount, setCharCount]= useState(0);
  const charLimit = 500;

  const handleSubmit = async (e) => {
  e.preventDefault();

  // Reset error messages
  setNameError("");
  setEmailError("");
  setPhoneError("");
  setSubjectError("");
  setMessageError("");


  if (!name) {
    setNameError("Name cannot be empty");
    return;
  }


  const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
  if (!email.match(emailRegex)) {
    setEmailError("Invalid email address");
    return;
  }

  if (!phonenumber) {
    setPhoneError("Blank phone number");
    return;
  }
  if (!subject) {
    setSubjectError("Blank message subject");
    return;
  }
  if (!message) {
    setMessageError("Type the message");
    return;
  }
 


    const formData = {
      name: name,
      email: email,
      phonenumber: phonenumber,
      subject: subject,
      message: message,
    };

    try {
      const response = await API.sendMessage(formData);
      if (response.status === 201) {
        setName("");
        setEmail("");
        setPhoneNumber("");
        setSubject("");
        setMessage("");
        toast({
          title: "Message sent successfully",
          status: "success",
          description: "Your message has been sent successfully",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      } else {
        // Handle the error
        toast({
          title: "Failed To send message",
          status: "error",
          description: "Failed to send the message. Please try again later",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      }
    } catch (error) {
      // Handle network error
      toast({
        title: "Error",
        status: "error",
        description:
          "Failed to send the message. Please check your internet connection and try again.",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
  };

  const openTelegramLink = () => {
    
    window.open("https://t.me/Crypto_Hub_Investment_1");
  };

  const handleMessageChange = (e) => {
    const newMessage = e.target.value;
    setMessage(newMessage);
    setCharCount(newMessage.length);
  };

  return (
    
      <Flex marginTop={10} flexDirection={{ base: "column", md: "row" }}>
        <VStack
          pt={{ base: 2, md: 50 }}
          mb={5}
          pl={2}
          mt={0}
          w={{ base: "90%", md: "400px" }}
        >
          <Box>
          
            <VStack pl={4} spacing={3}   align="flex-start"   >
            <a href="mailto: cryptohubbinvestment@gmail.com">
              <Button
                fontSize={"15px"}
                fontWeight={"400"}
                fontFamily={"sans-serif"}
                size="md"
                height="48px"
                width={{ base: "100%", md: "200px" }}
                _hover={{ bg: "transparent" }} 
                _focus={{ bg: "transparent" }}
                bg="transparent"
                leftIcon={<MdEmail color="#fe6600" size={20} />}
                title="mailto"
              >
               cryptohubbinvestment@gmail.com
              </Button>
              </a>
              <Button
                fontSize={"15px"}
                fontWeight={"400"}
                fontFamily={"sans-serif"}
                size="md"
                height="48px"
                width={{ base: "100%", md: "200px" }}
                _hover={{ bg: "transparent" }} 
                _focus={{ bg: "transparent" }}
                bg="transparent"
                pointerEvents="none"
                leftIcon={<MdLocationOn color="#fe6600" size={20} />}
              >
                UK
              </Button>
              <Button
                fontSize={"15px"}
                fontWeight={"400"}
                fontFamily={"sans-serif"}
                size="md"
                height="48px"
                width={{ base: "100%", md: "200px" }}
                onClick={openTelegramLink}
                title="Click to open"
              >
                Telegram Account
              </Button>
            </VStack>
          </Box>
        </VStack>
        <Box width={{ base: "90%", md: "30%" }} height="auto" pt={5} p={2}>
          <Stack>
            <Heading
              fontSize={{ base: "10px", md: "20px" }}
              lineHeight={1.25}
              fontWeight={700}
              mb={5}
            >
              Leave us message.
            </Heading>
            <form onSubmit={handleSubmit}>
              <FormControl isRequired isInvalid={nameError}>
                <Input
                  textAlign={{ base: "center", md: "left" }}
                  fontSize={{ base: "1rem", md: "1rem" }}
                  lineHeight={1.5}
                  type="text"
                  placeholder="Full Name"
                  variant="outline"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <FormErrorMessage>{nameError}</FormErrorMessage>
              </FormControl>
              <FormControl isRequired marginTop={5} isInvalid={emailError}>
                <Input
                  textAlign={{ base: "center", md: "left" }}
                  fontSize={{ base: "1rem", md: "1rem" }}
                  lineHeight={1.5}
                  placeholder="Email Address"
                  type="email"
                  variant="outline"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <FormErrorMessage>{emailError}</FormErrorMessage>
              </FormControl>
              <FormControl isRequired marginTop={5} isInvalid={phoneError}>
                <Input
                  textAlign={{ base: "center", md: "left" }}
                  fontSize={{ base: "1rem", md: "1rem" }}
                  lineHeight={1.5}
                  type="number"
                  variant="outline"
                  placeholder="Phone Number"
                  value={phonenumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
                <FormErrorMessage>{phoneError}</FormErrorMessage>
              </FormControl>
              <FormControl isRequired marginTop={5} isInvalid={subjectError}>
                <Input
                  textAlign={{ base: "center", md: "left" }}
                  fontSize={{ base: "1rem", md: "1rem" }}
                  lineHeight={1.5}
                  type="text"
                  placeholder="Subject"
                  variant="outline"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                />
                <FormErrorMessage>{subjectError}</FormErrorMessage>
              </FormControl>

              <FormControl isRequired marginTop={5} isInvalid={messageError}>
                <Textarea
                  textAlign={{ base: "center", md: "left" }}
                  fontSize={{ base: "1rem", md: "1rem" }}
                  lineHeight={1.5}
                  height={{ base: "4rem", md: "4rem" }}
                  value={message}
                  type="text"
                  maxLength={charLimit}
                  onChange={handleMessageChange}
                  placeholder="Write your message here..."
                />
                <FormErrorMessage>{messageError}</FormErrorMessage>
                {charCount}/{charLimit}
              </FormControl>
              <Button
                marginTop={5}
                textColor={"white"}
                width={{ base: "100%", md: "200px" }}
                height={"50px"}
                bgColor={"#fe6600"}
                cursor={"pointer"}
                marginBottom={10}
                type="submit"
                fontSize={"16px"}
                fontWeight={"700"}
                transition={"all 0.5s ease 0s"}
                borderRadius={"15px"}
                display={"inline-block"}
                onClick={handleSubmit}
              >
                Send Message
              </Button>
            </form>
          </Stack>
        </Box>
      </Flex>

  );
};

export default Contact;
