export const StatusData = [
    {
     title: "Deposits",
      count: "1,995",
     
    },
    {
        title: "Withdrawals",
        count: "1,995",
    },
    {
        title: "Profit",
        count: "1,995",
    },
    {
        title: "Transactions",
        count: "1,995",
    },
    {
        title: "Users",
        count: "1,995",
    }

  ];
