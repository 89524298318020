import React from "react";
import { Flex, HStack, Spacer, Text, VStack } from "@chakra-ui/react";
import UserBadge from "./UserBadge";

const TopBar = () => {
  
  const userDetails = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo'))
  : null;

  return (
    <>
      <Flex py={3} px={5} alignItems="center" m={4} mt={2} w="80vw" bg="white" h="10vh">
        <VStack alignItems="left" spacing="3px">
          <HStack>
            <Text fontSize="1.5em">Welcome,  {userDetails?.username} </Text>
          </HStack>
        </VStack>
        <Spacer />
        <UserBadge/>
      </Flex>
    </>
  );
};

export default TopBar;
