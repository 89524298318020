import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
} from '@chakra-ui/react';
import { FaUsers } from 'react-icons/fa';
import { RiLuggageDepositLine } from 'react-icons/ri';
import { MdPublishedWithChanges } from 'react-icons/md';

function StatsCard(props) {
  const { title, stat, icon } = props;

  return (
    <Stat
      px={[2, 4]}
      borderLeftWidth={1}
      borderLeftColor={'white'}
      py={[4, 5]}
      textAlign={['center', 'left']}
    >
      <Flex justifyContent={['center', 'space-between']}>
        <Box color={'white'} alignContent={'center'}>
          {icon}
        </Box>
        <Box pl={[0, 4]}>
          <StatNumber
            fontSize={['xl', '2xl']}
            textColor={'white'}
            fontWeight={'medium'}
            pb={[2, 0]}
            textAlign={['center', 'left']}
          >
            {stat}
          </StatNumber>
          <StatLabel
            fontWeight={'medium'}
            isTruncated
            textColor={'black'}
            maxW={['120px', 'auto']}
          >
            {title}
          </StatLabel>
        </Box>
      </Flex>
    </Stat>
  );
}

const Statistics = () => {
  const [registeredUsers, setRegisteredUsers] = useState(876500);
  const [onlineUsers, setOnlineUsers] = useState(5000);
  const [deposits, setDeposits] = useState(15020000);
  const [totalWithdrawn, setTotalWithdrawn] = useState(188420900);

  useEffect(() => {
    const registeredUsersInterval = setInterval(() => {
      // Increment the "Registered Users" stat after 1 hour
      const randomIncrement = Math.floor(Math.random() * 100); // You can adjust the increment as needed
      setRegisteredUsers(prevStat => prevStat + randomIncrement);
    }, 3600000); // 1 hour in milliseconds

    const otherStatsInterval = setInterval(() => {
      // Increment other stats every 30 seconds
      const randomIncrement = Math.floor(Math.random() * 1000); // You can adjust the increment as needed
      setOnlineUsers(prevStat => prevStat + randomIncrement);
      setDeposits(prevStat => prevStat + randomIncrement);
      setTotalWithdrawn(prevStat => prevStat + randomIncrement);
    }, 5000); // 30 seconds in milliseconds

    return () => {
      clearInterval(registeredUsersInterval);
      clearInterval(otherStatsInterval);
    };
  }, []);

  return (
    <Box bgColor={'#fe6600'} mb={20} boxShadow={'sm'} borderWidth={1}>
      <SimpleGrid columns={[1, 4]} spacing={[4, 4]} p={[4, 4]}>
        <StatsCard
          title={'Registered Users'}
          stat={registeredUsers}
          icon={<FaUsers size={'3em'} />}
        />
        <StatsCard
          title={'Online Users'}
          stat={onlineUsers}
          icon={<FaUsers size={'3em'} />}
        />
        <StatsCard
          title={'Deposits'}
          stat={deposits}
          icon={<RiLuggageDepositLine size={'3em'} />}
        />
        <StatsCard
          title={'Total Withdrawn'}
          stat={totalWithdrawn}
          icon={<MdPublishedWithChanges size={'3em'} />}
        />
      </SimpleGrid>
    </Box>
  );
};

export default Statistics;
