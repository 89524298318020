import { Routes, Route, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { Box } from "@chakra-ui/react";
import Home from "./pages/Home/Home";
import Coins from "./components/Currency/Coins";
import CoinDetails from "./components/Currency/CoinDetails";
import VerifyEmail from "./components/PageComponents/Email/VerifyEmail";
import Exchanges from "./components/Currency/Exchanges.jsx";
import SignIn from "./pages/Account/SignIn";
import SignUp from "./pages/Account/SignUp";


import Dashboard from "./pages/Dashboard/Dashboard";
import AccountDeposit from "./pages/Dashboard/AccountDeposit";
import Withdrawal from "./pages/Dashboard/Withdrawal";
import Settings from "./pages/Dashboard/Settings";
import Contact from "./pages/Contact/Contact";
import Layout from "./components/Layout/Layout";
import Referral from "./pages/Dashboard/Referrals";
import DepositHistory from "./pages/Dashboard/History/DepositHistory";
import HistoryLayout from "./components/Layout/HistoryLayout";
import WithdrawalHistory from "./pages/Dashboard/History/WithdrawalHistory";
import EarningHistory from "./pages/Dashboard/History/EarningHistory";

import FAQPage from "./pages/FAQPage";
import GoogleTranslate from "./components/PageComponents/Translation/GoogleTranslate";
import AdminLayout from "./components/Layout/AdminLayout";
import UserList from "./pages/Admin/Users/Users";
import Dash from "./pages/Admin/Home/AdminHome";
import Analytics from "./pages/Admin/Analytics/Analytics";
import Transactions from "./pages/Admin/Transactions/Transactions";
import NotFound from "./components/PageComponents/NotFound";
import ForgotPassword from "./components/PageComponents/Reset/ForgotPass";
import ResetPasswordCard from "./components/PageComponents/Reset/ResetPass";
import NavBar from "./components/PageComponents/NavBar";
import PrivateRoute from "./utils/privateRoutes";
import About from "./pages/Home/About";
import Messages from "./pages/Admin/Messages/Messages";
import Loading from "./components/Loading/Loading";
import Investiment from "./pages/Home/Investment.js";
import WithdrawalTable from "./pages/Home/WithdrawalTable.js";
import DepositTable from "./pages/Home/DepositTable.js";

function App() {
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [location]);
  return (
    <>
      {loading ? (
        <Box>
          <Loading />
        </Box>
      ) : (
        <Box>
          <GoogleTranslate />
          <NavBar /> 
          <Routes>
            <Route index element={<Home />} />
            <Route path="/login" element={<SignIn />} />
            <Route path="/about" element={<About />} />
            <Route path="/register" element={<SignUp />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password/:token" element={<ResetPasswordCard />} />
            <Route path="/verify" element={<VerifyEmail />} />
            <Route path="/investment-plan" element={<Investiment/>} />
            <Route path="/faq" element={<FAQPage />} />
            <Route path="/online" element={<DepositTable/>} />
            <Route path="/with" element={<WithdrawalTable/>} />
            <Route path="/coins" element={<Coins />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/exchanges" element={<Exchanges />} />
            <Route path="/coin/:id" element={<CoinDetails />} />
     
            <Route path="/" element={<AdminLayout />}>
              <Route path="/admin/dashboard" element={
              <PrivateRoute>
              <Dash />
            </PrivateRoute>
              } />
              <Route path="/admin/analytics" element={<Analytics />} />
              <Route path="/admin/users" element={<UserList />} />
              <Route path="/admin/messages" element={<Messages />} />
              <Route path="/admin/transactions" element={<Transactions />} />
            
            </Route>

            <Route path="/" element={
            <PrivateRoute >
              <Layout />
            </PrivateRoute>
            }>
            
              <Route path="/client/dashboard" element={<Dashboard />} />
              <Route path="/client/deposit" element={<AccountDeposit />} />
              <Route path="/client/withdrawal" element={<Withdrawal />} />
              <Route path="/" element={<HistoryLayout />}>
                <Route
                  path="/client/deposit_history"
                  element={<DepositHistory />}
                />
                <Route
                  path="/client/withdrawal_history"
                  element={<WithdrawalHistory />}
                />
                <Route
                  path="/client/earning_history"
                  element={<EarningHistory />}
                />
              </Route>
              <Route path="/client/referral" element={<Referral />} />
              <Route path="/client/settings" element={<Settings />} />
             
            </Route>
          
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Box>
    )}
    </>
  );
}
export default App;
