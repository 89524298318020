import { Box, HStack, Flex } from "@chakra-ui/react";
import React  from "react";
import { Outlet} from "react-router-dom";
import Sidebar from "../ClientComponents/Sidebar";
import TopBar from "../PageComponents/TopBar";

const Layout = () => {


  
  return (
    <HStack spacing={0} overflowX={"scroll"} overflowY={"scroll"}>
      <Sidebar />
      <Flex bg="#F9F9F9" direction="column"   >
      <TopBar />
        <Box overflowY="hidden" h="89.5vh" pr={4}  >
          <Outlet />
        </Box>
      </Flex>
    </HStack>
  );
};

export default Layout;