import {HStack} from "@chakra-ui/react";
import React from "react";
import Content from "../../pages/Dashboard/History/Content";

const HistoryLayout = () => {
  return (
    <HStack  overflowY={"hidden"} pl={4} w={'100%'} pt={8} >
     <Content/>
    </HStack>
  );
};

export default HistoryLayout;