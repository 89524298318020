import axios from "axios";


const baseURL = 'https://cryptohub-backend-8lzy.onrender.com/api';

const defaultConfig = {
  baseURL,
  timeout: 6000,
  headers: {
    "Content-Type": "application/json",
    
  },
  validateStatus: function (status) {
    console.log(`Server responded with status ${status}`);
    return status < 500; 
  },
};

const api = axios.create({ ...defaultConfig });

class API {
  /*------------------------------------------AUTH---------------------------------------------- */
  // API endpoint for logging in users
  async loginUser(user) {
    return api.post("/users/login", user);
  }
   
  async registerUser(user) {
    return api.post("/users/register", user);
  }
  async confirmEmail(formData) {
    return api.post("/users/confirm-email", formData);
  }
  async resetPassword(formData) {
    return api.post("/users/reset-password/:token", formData);
  }

    

  async getClientFinanceData(userId) {
   
    return api.get(`/finance/client-dashboard/${userId}`);
      
    } 

  async makeDeposit(userId) {
   
     return api.post(`/finance/client-deposit/${userId}`);
    }
  
  async makeWithdrawal(userId) {
 
      return api.post(`/finance/client-withdraw/${userId}`);
   
  }

  async getDepositHistory(userId) {
    return api.get(`/finance/deposit-history/${userId}`);
      
  }

  async sendMessage(formData) {
    return api.post("/contact/message", formData);
  }

  async getMessage() {
    return api.get("/contact/display-messages");
  }

  async getAllUsers() {
    return api.get("/users/all-users");
  }

  async updateprofile(userId,formData) {
    return api.post(`/users/update-user/${userId}`,formData);
  }

  async updatePlan(userId, formData) {
    return api.post(`/users/update-plan/${userId}`, formData);
  }


  async deleteUser(userId) {
    return api.delete(`/users/delete-user/${userId}`);
  }
}




const instance = new API();

export default instance;
