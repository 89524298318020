import React from "react";
import { Box,Spacer } from "@chakra-ui/react";
import chartOptions from "../../../constants/ChartOption.json";
import Chart from "react-apexcharts";
export default function Analytics() {
  return (
    <>
    <Box
    width="100%"
    bg="#E2E8F0"
    borderRadius="5px"
    height="80%"
    mt="30px"
  >
    <Spacer />
    <Chart
      options={chartOptions.options}
      series={chartOptions.series}
      type="line"
      height="90%"
    />
  </Box>
  </>
  );
}
