import { Box, HStack,Flex} from "@chakra-ui/react";
import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../AdminComponents/sidebar/Sidebar";
//import TopBar from "../AdminComponents/Notification/TopBar";
const AdminLayout = () => {
  return (
    <HStack  overflowX={'scroll'} overflowY={"scroll"}>
      <Sidebar/>
      <Flex bg="white" direction="column" maxWidth="85vw" h="100vh">
        <Box  h="100vh" w={'100vw'} pt={4} >
          <Outlet />
        </Box>
        </Flex>
    </HStack>
  );
};
export default AdminLayout;