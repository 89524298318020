import React from "react";
import { Link } from "react-router-dom";
import images from "../../constants/images";
import {
  Box,
  Flex,
  HStack,
  VStack,
  IconButton,
  useDisclosure,
  useMediaQuery,
  Drawer,
  Image,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  Heading,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";

const NavBar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");

  return (
    <Box
      as="header"
      bg="whiteAlpha.200"
      borderBottomWidth={1}
      boxShadow="sm"
      h={90}
      py={6}
      pt="8"
      w="100%"
    >
      <Flex
        alignItems="center"  // Center vertically for large screens
        bg="whiteAlpha.200"
        justifyContent="space-between" // Space between items for large screens
        w="100%"
        px={10} 
      >
        <HStack>
        <Image
       
        alt={'Logo'}
        h={30}
        w={30}
        src={images.logo}/>
        <Heading fontWeight={700}
       fontSize={{ base: '14px', md: '20px' }}
        lineHeight={{ base: '20px', md: '30px' }}
      >Cryptohub Investiment </Heading>
        </HStack>
        {isLargerThan768 && (
          <HStack as="nav" spacing={10} marginLeft={80}>
            <Link to="/">Home</Link>
            <Link to="/about">About</Link>
            <Link to="/faq">FAQ</Link>
            <Link to="/contact">Contact</Link>
            <Link to="/exchanges">Exchanges</Link>
            <Link to="/coins">Coins</Link>
            <Link to="/login">Login</Link>
          </HStack>
        )}

        <Box display={{ base: "block", md: "none" }}>
          <IconButton
            icon={<HamburgerIcon />}
            aria-label="Open menu"
            onClick={onOpen}
            style={{ marginLeft: 0 }}
          />
        </Box>
      </Flex>
      <MobileMenu isOpen={isOpen} onClose={onClose} />
    </Box>
  );
};

const MobileMenu = ({ isOpen, onClose }) => {
  return (
    <Drawer isOpen={isOpen} placement="right" size='xs' onClose={onClose}>
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody>
            <VStack spacing={4} align="stretch">
              <Link to="/" onClick={onClose}>
                Home
              </Link>
              <Link to="/about" onClick={onClose}>
                About
              </Link>
              <Link to="/faq" onClick={onClose}>
                FAQ
              </Link>
              <Link to="/contact" onClick={onClose}>
                Contact
              </Link>
              <Link to="/exchanges" onClick={onClose}>
                Exchanges
              </Link>
              <Link to="/coins" onClick={onClose}>
                Coins
              </Link>
              <Link to="/login" onClick={onClose}>
                Login
              </Link>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default NavBar;
