import { Navigate, useLocation } from "react-router-dom";
import { useAuthState } from "../context";

const PrivateRoute = ({ children }) => {
  let location = useLocation();
  const {  isAuthenticated, loading } = useAuthState(); 

  if (loading) {
    return <p className="container">Checking auth..</p>;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
};

export default PrivateRoute;