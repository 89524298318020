import React from "react";
import { useNavigate } from "react-router-dom";
//import { ROLES } from "../../utils/roles";
//import { useAuthState } from "../../context";

import {
  HStack,
  Box,
  Text,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  IconButton,
} from "@chakra-ui/react";
import {
  FiHome,
  FiCalendar,
  FiRefreshCcw,
  FiDollarSign,
  FiSettings,
  FiGlobe,
  FiMenu,
} from "react-icons/fi";
  


const SideBarItemData = [
  {
    id: 0,
    item_name: "Dashboard",
    icon: FiHome,
    route: "/client/dashboard",
    //role: [ROLES.Client],
  },
  {
    id: 1,
    item_name: "Deposit",
    icon: FiDollarSign,
    route: "client/deposit",
    //role: [ROLES.Client],
  },
  {
    id: 2,
    item_name: "Withdrawal",
    icon: FiGlobe,
    route: "client/withdrawal",
    //role: [ROLES.Client],
  },
  {
    id: 3,
    item_name: "History",
    icon: FiCalendar,
    route: "client/deposit_history",
    //role: [ROLES.Client],
  },
  {
    id: 4,
    item_name: "Referrals",
    icon: FiRefreshCcw,
    route: "client/referral",
    //role: [ROLES.Client],
  },
  {
    id: 5,
    item_name: "Settings",
    icon: FiSettings,
    route: "client/settings",
    //role: [ROLES.Client],
  },
];

const SideBarItem = (props) => {
  
  const navigate = useNavigate();

  const handleItemClick = () => {
    props.setCurrent(props.index);
    props.onClose(); 
    navigate(props.route);
  };


  return (
    <HStack
      _hover={{ color: "brand.300" }}
      cursor="pointer"
      py="25px"
      pl="20px"
      className="sidebaritem"
      id={`sidebaritem-${props.index}`}
      onClick={handleItemClick}
    >
      <props.icon boxSize={30} pl="20px" />
      <Text>{props.item_name}</Text>
    </HStack>
  );
};

const Sidebar = () => {
  const [current, setCurrent] = React.useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  

  //logic to set active sidebar item
  React.useEffect(() => {
    var sidebarItemsArray = document.getElementsByClassName("sidebaritem");
    var i;
    for (i = 0; i < sidebarItemsArray.length; i++) {
      sidebarItemsArray[i].className = sidebarItemsArray[i].className.replace(
        "active-sidebaritem",
        ""
      );
      if (sidebarItemsArray.length > 0) {
        sidebarItemsArray[i].classList.remove("active-sidebaritem");
      }
      const itemIndex = current;
      if (sidebarItemsArray[itemIndex] !== undefined)
        sidebarItemsArray[itemIndex].className += " active-sidebaritem";
    }
  }, [current]);

  return (
    <>
      {/* Responsive design for phone screens */}
      <Box display={["block", "none"]} textAlign='left'  >
        <IconButton
          ref={btnRef}
          icon={<FiMenu />}
          style={{ background: 'transparent', border: 'none', cursor: 'pointer' }}
          variant="outline"
          onClick={onOpen}
        />
      </Box>

      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        finalFocusRef={btnRef}
       
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Menu</DrawerHeader>
          <DrawerBody>

            {SideBarItemData.map((item, index) => (
              <SideBarItem
                key={item.id}
                item_name={item.item_name}
                icon={item.icon}
                route={item.route}
                index={index}
                setCurrent={setCurrent}
                onClose={onClose}
              />
            ))}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      {/* Non-responsive design for screens larger than phones */}
      <Box display={["none", "block"]} borderRightWidth={1} borderColor={'brand.200'}>
        <Box bg="white" w="15vw" minH="100vh" >
          {SideBarItemData.map((item, index) => (
            <SideBarItem
              key={item.id}
              item_name={item.item_name}
              icon={item.icon}
              route={item.route}
              index={index}
              setCurrent={setCurrent}
              onClose={onClose} 
            />
          ))}
        </Box>
      </Box>
    </>
  );
};

export default Sidebar;
