/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import {
  Flex,
  Heading,
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  chakra,
  Box,
  useToast,
  Link,
  FormErrorMessage,
  Avatar,
  FormControl,
  FormHelperText,
  InputRightElement,
} from "@chakra-ui/react";
import { FaUserAlt, FaLock } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { loginUser, useAuthState, useAuthDispatch } from "../../context";
import { useForm } from "../../utils/useForm";

const CFaUserAlt = chakra(FaUserAlt);
const CFaLock = chakra(FaLock);
//const CMdMarkEmailRead = chakra(MdMarkEmailRead);

const SignIn = () => {
  const toast = useToast();
  const dispatch = useAuthDispatch();
  const { loading } = useAuthState();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const handleShowClick = () => setShowPassword(!showPassword);

  const {
    handleSubmit,
    handleChange,
    data: user,
    errors,
  } = useForm({
    validations: {
      username: {
        custom: {
          isValid: (value) => value.length > 0,
          message: "Username cannot be blank.",
        },
      },
      password: {
        custom: {
          isValid: (value) => value.length > 0,
          message: "Password cannot be blank.",
        },
      },
    },

    onSubmit: async (e) => {
      try {
        const payload = {
          username: user.username,
          password: user.password,
        };

        let response = await loginUser(dispatch, payload);
        console.log(response);
        if (response.token) {
          toast({
            title: "Login Successful",
            status: "success",
            duration: 1000,
            isClosable: true,
            position: "top",
          });

          //Determine redirect based on user role
          if (response.role === "client") {
            navigate("/client/dashboard");
          } else if (response.role === "admin") {
            navigate("/admin/dashboard");
          } else {
            // Handle unexpected role here (optional)
            console.log("Unknown role:", response.role);
          }
        }
      } catch (error) {
        toast({
          title: "Unsuccessful Login",
          status: "error",
          description:
            "Kindly use valid login credentials and check on connectivity",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      }
    },
  });

  const handleEnterKey = (event) => {
    if (event.keyCode === 13) {
      handleSubmit();
    }
  };

  return (
    <Flex
      flexDirection="column"
      width="100wh"
      height="100vh"
      backgroundColor="white"
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        flexDir="column"
        mb="2"
        justifyContent="center"
        alignItems="center"
      >
        <Avatar bg="#fe6600" />
        <Heading color="#222222" fontSize={"20px"} lineHeight={1.25} pb={4}>
          Login to your account
        </Heading>
        <Box minW={{ base: "90%", md: "468px" }}>
          <form onSubmit={handleSubmit}>
            <Stack spacing={4} p="1rem" backgroundColor="white" boxShadow="md">
              <FormControl id="username" isRequired isInvalid={errors.username}>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<CFaUserAlt color="#fe6600" />}
                  />
                  <Input
                    textAlign={"start"}
                    type="text"
                    placeholder="username"
                    value={user.username || ""}
                    onChange={handleChange("username")}
                  />
                </InputGroup>
                <FormErrorMessage>{errors.username}</FormErrorMessage>
              </FormControl>
              {/*
              <FormControl id="email" isRequired >
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<CMdMarkEmailRead color="#fe6600" />}
                  />
                  <Input
                    textAlign={"start"}
                    type="email"
                    placeholder="email address"
                    value={user.email || ""}
                  onChange={handleChange("email")}
                  />
                </InputGroup>
              </FormControl>
  */}
              <FormControl id="password" isRequired isInvalid={errors.password}>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    color="gray.300"
                    children={<CFaLock color="#fe6600" />}
                  />
                  <Input
                    type={showPassword ? "text" : "password"}
                    textAlign={"start"}
                    placeholder="Password"
                    value={user.password || ""}
                    onChange={handleChange("password")}
                  />
                  <InputRightElement width="4.5rem">
                    <Button h="1.75rem" size="sm" onClick={handleShowClick}>
                      {showPassword ? "Hide" : "Show"}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>{errors.password}</FormErrorMessage>
                <FormHelperText textAlign="right">
                  <Link
                    onClick={() => {
                      navigate("/forgot-password");
                    }}
                  >
                    forgot password?
                  </Link>
                </FormHelperText>
              </FormControl>

              <Button
                borderRadius={0}
                //type="submit"
                textColor={"white"}
                variant="solid"
                bgColor={"#fe6600"}
                width="full"
                isDisabled={!user.password || !user.username}
                bg={
                  !user.password || !user.username ? "brand.200" : "brand.300"
                }
                color={
                  !user.password || !user.username ? "brand.300" : "brand.200"
                }
                _focus={{ outline: "none" }}
                _active={{ outline: "none" }}
                isLoading={loading}
                onClick={handleSubmit}
              >
                Login
              </Button>
            </Stack>
          </form>
        </Box>
      </Stack>
      <Box>
        New to us?{" "}
        <Link
          color="#fe6600"
          onClick={() => {
            navigate("/investment-plan");
          }}
        >
          Sign Up
        </Link>
      </Box>
    </Flex>
  );
};

export default SignIn;
