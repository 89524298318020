import {
  Box,
  Heading,
  SimpleGrid,
    VStack,
    Text,
    Divider,
    Button,
} from '@chakra-ui/react';
import { useNavigate } from "react-router-dom";
import PlanCard from '../../Cards/PlanCard';
import { plandata } from '../../constants/PlansList';

 const Investiment=()=> {
  
  const navigate = useNavigate();
  const promoPackage = plandata[4];

  const handleGetStarted = () => {
    navigate(`/register?plan=${promoPackage.title}`);
  };

  
  return (
    <Box bgColor={'#212529'} pb={20}>
    <Box pl={10} pt={10}  alignItems={'center'} pr={10} mb={10} >
    <Heading fontSize={'30px'} pb={10} color={'white'} fontWeight={800} fontFamily={"sans-serif"} lineHeight={1.25} align={"center"} mb={'15px'}>
    Investiment Plans
      </Heading>
      <SimpleGrid columns={[1, 2, 3, 4]} spacing="10px" >
  {plandata.slice(0, 4).map(plan => (
    <PlanCard key={plan.id} {...plan} />
  ))}
</SimpleGrid>
    </Box>
    <Box bgColor={'white'} 
    pb={40}
    ml={10}
    mr={10}
     borderWidth={1}>
      <VStack >
      <Heading width={'full'}  fontFamily={"sans-serif"} textColor={'white'} textAlign={'center'} pt={'60px'}  bgColor={"#fe6600"} fontSize={'28px'}
         lineHeight={'40px'}
         h={40}
          mb={'15px'} fontWeight={'700'} 
          >
         {promoPackage.title}
          </Heading>
      <Text fontSize={'20px'} fontWeight={700} color={'#2d2d2d'}
    lineHeight={1.1}
    fontFamily={'sans-serif'} >{promoPackage.profit}</Text>
      <Divider/>
  <Text color={'#747474'} fontSize={'15px'} textColor={'#747474'} fontWeight={400} lineHeight={'28px'}
        fontFamily ={'sans-serif'}>{promoPackage.minimum}</Text>
      <Divider/>
      <Text color={'#747474'} fontSize={'15px'} textColor={'#747474'} fontWeight={400} lineHeight={'28px'}
        fontFamily ={'sans-serif'}>{promoPackage.maximum}</Text>
      <Divider/>
      <Text color={'#747474'} fontSize={'15px'} textColor={'#747474'} fontWeight={400} lineHeight={'28px'}
        fontFamily ={'sans-serif'}>{promoPackage.referral}</Text>
      <Divider/>
      <Text color={'#747474'} fontSize={'15px'} textColor={'#747474'} fontWeight={400} lineHeight={'28px'}
        fontFamily ={'sans-serif'}>{promoPackage.reinvestment}</Text>
        <Divider/>
      <Button onClick={handleGetStarted}  color={'white'}  width={200} height={"60px"} bgColor={"#fe6600"} cursor={"pointer"} fontSize={'16px'} fontWeight={'700'} transition={"all 0.5s ease 0s"} borderRadius={'15px'} display={"inline-block"}>Get Started</Button>
      </VStack>
    </Box>
    </Box>
  );
}
export default Investiment;