import React, { useState, useEffect } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  Box,
} from "@chakra-ui/react";
const allUsernames = [
  "Liam", "Olivia", "Noah", "Ava", "Elijah", "Sophia", "Oliver", "Isabella", "Lucas", "Mia", 
  "Mason", "Charlotte", "Ethan", "Amelia", "Harper", "William", "Evelyn", "James", "Abigail", "Alexander",
  "Emily", "Michael", "Ella", "Benjamin", "Avery", "Daniel", "Scarlett", "Henry", "Grace", "Matthew", 
  "Chloe", "Jackson", "Lily", "Samuel", "Aria", "Sebastian", "Aubrey", "David", "Zoe", "Carter", 
  "Riley", "Joseph", "Penelope", "Owen", "Madison", "Wyatt", "Layla", "John", "Camila"
];


const initialVisibleUsernames = allUsernames.slice(0, 4); // Initial 4 usernames
let lastIndexDisplayed = 0;

const DepositTable = () => {
  const [visibleUsernames, setVisibleUsernames] = useState(initialVisibleUsernames);

  useEffect(() => {
    const interval = setInterval(() => {
      let nextIndex = lastIndexDisplayed + 4;
      if (nextIndex >= allUsernames.length) {
        // Wrap around to the beginning if reached the end
        nextIndex = 0;
      }
      const newVisibleUsernames = allUsernames.slice(nextIndex, nextIndex + 4);
      setVisibleUsernames(newVisibleUsernames);
      lastIndexDisplayed = nextIndex;
    }, 5000); // Update every 2 seconds

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Box p={4} shadow="md" borderWidth="1px" borderRadius="md">
      <Table variant="striped" size="sm" colorScheme="blue" isResponsive>
        <TableCaption>Deposits</TableCaption>
        <Thead>
          <Tr>
            <Th>Username</Th>
            <Th>Deposits($)</Th>
          </Tr>
        </Thead>
        <Tbody>
          {visibleUsernames.map((username, index) => (
            <Tr key={index}>
              <Td>{username}</Td>
              <Td>{Math.floor(Math.random() * 10000)}</Td> {/* Random deposit value */}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default DepositTable;
