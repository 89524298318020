import {
  Box,
  Divider,
  Text,
  HStack,
  useClipboard,
  Flex,
  Input,
  Button,
  VStack,
  FormControl,
  Select,
  FormLabel,
} from '@chakra-ui/react';
//import axios from 'axios';
import API from "../../utils/api";
import {
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from '@chakra-ui/react';
import React, {useState} from 'react';

const AccountDeposit = () => {
  const [method, setMethod] = useState('');
  const [plan, setPlan] = useState('');
  const [amount, setAmount] = useState('');
  
  const bitcoinAddress = '3H2TJiUQ6DLLpUvaW3YU1hy9tzxeCrhMiC';
  const ethereumAddress = '0x81777a8726bd3C844E1d505C787710b912F6dAfb';
  const usdtAddress = 'TNy9J2qhR2xxm5huq3srHJvUk7qwwKVGKj'; 


  const { onCopy: onBitcoinCopy, hasCopied: bitcoinHasCopied } = useClipboard(bitcoinAddress);
  const { onCopy: onEthereumCopy, hasCopied: ethereumHasCopied } = useClipboard(ethereumAddress);
  const { onCopy: onBNBCopy, hasCopied: bnbHasCopied } = useClipboard(usdtAddress);
 
  const [label, setLabel] = useState('');

  const userId = JSON.parse(localStorage.getItem("userInfo"))
    ? JSON.parse(localStorage.getItem("userInfo"))._id
    : null;

  const handleDeposit = async () => {
    if (!method || !plan || !amount) {
      setLabel("All fields are required.");
      return;
    }

    try {
      if (userId) {
        // Make a deposit for the user
        const response = await API.makeDeposit(userId);
        if (response.status === 200) {
          setLabel('Deposit request has been received. Please copy the Wallet Address of your chosen payment method and make payment.');
        } else {
          // Handle error
          setLabel('Deposit request failed.');
        }
      }
    } catch (error) {
    
      setLabel('An error occurred while processing your request.');
    }
  };
  
  return (
    <Box >
      <HStack >
      <Box boxShadow={'lg'} borderWidth={'1px'}  height={'80vh'} p={4} m={4} borderRadius={15} bg={"white"}>
          <VStack>
          <Box alignSelf={"flex-start"}>
            <Text fontSize={"2xl"} >Deposit funds </Text>
            </Box>
            <Divider />
            <Text pt={8} fontSize={'md'}>Bitcoin Wallet Address</Text>
            <Divider/>
            
            <Flex >
              <Input
                placeholder={'3H2TJiUQ6DLLpUvaW3YU1hy9tzxeCrhMiC'}
                width={'470px'}
                value={bitcoinAddress}
                onChange={() => {}}
                mr={2}
              />
              <Button onClick={onBitcoinCopy}>{bitcoinHasCopied ? 'Copied!' : 'Copy'}</Button>
            </Flex>
            <Divider />
            <Text pt={4}>Ethereum ERC(20)</Text>
            <Flex mb={2}>
              <Input
                placeholder={'0x81777a8726bd3C844E1d505C787710b912F6dAfb'}
                width={'470px'}
                value={ethereumAddress}
                onChange={() => {}}
                mr={2}
              />
              <Button onClick={onEthereumCopy}>{ethereumHasCopied ? 'Copied!' : 'Copy'}</Button>
            </Flex>
            <Divider/>
            <Text  pt={4}>USDT TRC_20</Text>
            <Divider />
            <Flex mb={2}>
              <Input
                placeholder={'TNy9J2qhR2xxm5huq3srHJvUk7qwwKVGKj'}
                width={'470px'}
                value={usdtAddress}
                onChange={() => {}}
                mr={2}
              />
             <Button onClick={onBNBCopy}>{bnbHasCopied ? 'Copied!' : 'Copy'}</Button>
            </Flex>
            <Divider />
          {/*
            <Text  pt={4}>USDT Wallet Address</Text>
            <Divider />
            <Flex mb={2}>
              <Input
                width={'500px'}
                placeholder={'16NEEtG72njBTPPYjS5W8x9d4HaFEvM3oV'}
                value={usdtAddress}
                onChange={() => {}}
                mr={2}
              />
              <Button onClick={onUsdtCopy}>{usdtHasCopied ? 'Copied!' : 'Copy'}</Button>
            </Flex>
            <Divider />
  */}
          </VStack>
        </Box>
      
        <Box  boxShadow={'lg'} borderWidth={'1px'} height={'80vh'} p={4}   borderRadius={15} bg={"white"} >
          <VStack>
            <Text alignSelf={'flex-start'} fontSize={"2xl"} > Deposit Funds</Text>
            <Divider/>
            <Text textColor={'green'}> {label}</Text>
            <Box pt={10}>
              <FormControl id="preferredmethod" isRequired pb={4}>
                <FormLabel>Preferred Method</FormLabel>
                <Select placeholder="Select Preferred Method" width={'500px'}value={method}
          onChange={(event) => setMethod(event.target.value)}>
                  <option value="Bitcoin">Bitcoin</option>
                  <option value="Ethereum">Ethereum ERC-20</option>
                  <option value="USDT">USDT</option>
                </Select>
              </FormControl>
            </Box>
            <Box>
              <FormControl id="investmentplan" isRequired pb={4}>
                <FormLabel>Investment Plan</FormLabel>
                <Select placeholder="Select option" width={'500px'}value={plan}
          onChange={(event) => setPlan(event.target.value)}>
                  <option value="Beginner">Beginners Package</option>
                  <option value="Maxi">Maxi Plan</option>
                  <option value="Silver">Silver Package</option>
                  <option value="Gold">Gold Package</option>
                </Select>
              </FormControl>
            </Box>
            <Box>
              <FormControl id="Amount" isRequired pb={4}>
                <FormLabel>Amount [USD]</FormLabel>
                <NumberInput defaultValue={0}>
                  <NumberInputField width={'500px'} value={amount}
          onChange={(event) => setAmount(event.target.value)} />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </FormControl>
            </Box>
            <Button alignSelf={'flex-end'} colorScheme={'blue'} onClick={handleDeposit}>Deposit</Button>
          </VStack>
        </Box>
      </HStack>
    </Box>
  );
};

export default AccountDeposit;
