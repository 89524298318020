import React from 'react';
import { Center, Box, Text } from '@chakra-ui/react';

const VerifyEmailComponent = ({ email }) => {
  return (
    <Center height="50vh">
      <Box maxW="lg" mx="auto" p={8} borderWidth={1} borderRadius="md" shadow="md">
        <Text fontSize="xl" fontWeight="bold" mb={4} pl={20}>
          Verify Your Email
        </Text>
        <Text mb={2}>
          An email has been sent to <strong>email</strong>.
        </Text>
        <Text>
          Please check your email to complete the verification process.
        </Text>
      </Box>
    </Center>
  );
};

export default VerifyEmailComponent;
