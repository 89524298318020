import React, { useState, useEffect } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  Box,
} from "@chakra-ui/react";

const allUsernames = [
  "Ezra", "Aurora", "Leo", "Nova", "Hudson", "Stella", "Gabriel", "Paisley", "Nathan", "Violet",
  "Isaac", "Savannah", "Nicholas", "Hazel", "Jaxon", "Natalie", "Axel", "Nova", "Kai", "Ivy",
  "Theodore", "Willow", "Caleb", "Emilia", "Jesse", "Aurora", "Bennett", "Elise", "Xavier", "Eleanor",
  "Maxwell", "Aria", "Levi", "Naomi", "Miles", "Luna", "Jude", "Clara", "Lincoln", "Eloise",
  "Everett", "Adeline", "Eli", "Rose", "Silas", "Daisy", "Roman", "Seraphina", "Kian", "Genevieve"
];


const initialVisibleUsernames = allUsernames.slice(0, 4); // Initial 4 usernames
let lastIndexDisplayed = 0;

const WithdrawalTable = () => {
  const [visibleUsernames, setVisibleUsernames] = useState(initialVisibleUsernames);

  useEffect(() => {
    const interval = setInterval(() => {
      let nextIndex = lastIndexDisplayed + 4;
      if (nextIndex >= allUsernames.length) {
        // Wrap around to the beginning if reached the end
        nextIndex = 0;
      }
      const newVisibleUsernames = allUsernames.slice(nextIndex, nextIndex + 4);
      setVisibleUsernames(newVisibleUsernames);
      lastIndexDisplayed = nextIndex;
    }, 5000); // Update every 2 seconds

    return () => {
      clearInterval(interval);
    };
  }, []);


  return (
    <Box p={4} shadow="md" borderWidth="1px" borderRadius="md">
      <Table variant="striped" size="sm" colorScheme="blue" isResponsive>
        <TableCaption>Withdrawals</TableCaption>
        <Thead>
          <Tr>
            <Th>Username</Th>
            <Th>Withdrawals($)</Th>
          </Tr>
        </Thead>
        <Tbody>
          {visibleUsernames.map((username, index) => (
            <Tr key={index}>
              <Td>{username}</Td>
              <Td>{Math.floor(Math.random() * 10000)}</Td> {/* Random deposit value */}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default WithdrawalTable;
