import React, {useState} from 'react'
import {
    Button,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Input,
    useToast,
    InputGroup,
    Stack,
    InputRightElement,
    useColorModeValue,
  } from '@chakra-ui/react';
  import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import API from "../../../utils/api";
import { useParams } from 'react-router-dom';

   const ResetPasswordCard=()=>{
    const { token } = useParams();
    const navigate = useNavigate();
    const [password, setPassword] = useState("");
    const [confirmpassword, setConfirmPassword] = useState("");
//const [passwordError, setPasswordError] = useState("");
   //const [confirmpasswordError, setConfirmPasswordError] = useState("");
    const toast = useToast();
 
    const [showPassword, setShowPassword] = useState(false);

    const handleSubmit = async (e) => {
      e.preventDefault();
  
  
    
  
      const formData = {
        token: token,
        password: password,
        confirmpassword: confirmpassword
      };
  
      try {
        const response = await API.resetPassword(formData);
        if (response.status === 200)  {
         setPassword("")
         setConfirmPassword("")
          toast({
            title: "Password changed successfully proceed to login",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          navigate("/reset-password");
        }
          else {
            toast({
              title: 'User not found',
              status: 'error',
              description: 'No user with such email exists',
              duration: 3000,
              isClosable: true,
              position: 'top',
            });
          }
        } catch (error) {
          toast({
            title: 'Error',
            status: 'error',
            description: 'An error occurred. Please try again later.',
            duration: 3000,
            isClosable: true,
            position: 'top',
          });
        }
      };



    return (
      <Flex
        minH={'100vh'}
        align={'center'}
        justify={'center'}
        bg={useColorModeValue('gray.50', 'gray.800')}>
        <Stack
          spacing={4}
          w={'full'}
          maxW={'md'}
          bg={useColorModeValue('white', 'gray.700')}
          rounded={'xl'}
          boxShadow={'lg'}
          p={6}
          my={12}>
          <Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }}>
            Enter new password
          </Heading>
          <FormControl id="email" isRequired>
            <FormLabel>New Password</FormLabel>
            <InputGroup>
                <Input type={showPassword ? 'text' : 'password'}  value={password}
                  onChange={(e) => setPassword(e.target.value)} />
                <InputRightElement h={'full'}>
                  <Button
                    variant={'ghost'}
                    onClick={() =>
                      setShowPassword((showPassword) => !showPassword)
                    }>
                    {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
          </FormControl>
          <FormControl id="password" isRequired>
            <FormLabel>Confirm Password</FormLabel>
            <InputGroup>
                <Input type={showPassword ? 'text' : 'password'}  value={confirmpassword}
                  onChange={(e) => setConfirmPassword(e.target.value)} />
                <InputRightElement h={'full'}>
                  <Button
                    variant={'ghost'}
                    onClick={() =>
                      setShowPassword((showPassword) => !showPassword)
                    }>
                    {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
          </FormControl>
          <Stack spacing={6}>
            <Button
              bg={'blue.400'}
              color={'white'}
              _hover={{
                bg: 'blue.500',
              }}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Stack>
        </Stack>
      </Flex>
    );
  }
  export default ResetPasswordCard;