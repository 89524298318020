import React from "react";
import All from "../Transactions/Tabs/All/All";
import Pending from "../Transactions/Tabs/Pending/Pending";
import Approved from "../Transactions/Tabs/Approved/Approved";
import Cancelled from "./Tabs/Declined/Declined";
import {
  Box,
  Divider,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Flex,
} from "@chakra-ui/react";

const Transactions = () => {
  return (
    <>
      {" "}
      <Box h="100vh">
        <HStack></HStack>
        <Divider my={2} />
        <Flex>
          <Box width="100%" h="100vh">
            <Tabs
              h="100vh"
              variant="line"
              colorScheme="green"
              isLazy
              borderColor="transparent"
            >
              <TabList>
                <Tab>All</Tab>
                <Tab>Pending</Tab>
                <Tab>Approved</Tab>
                <Tab>Declined</Tab>
              </TabList>
              <TabPanels h="100vh">
                <TabPanel h="inherit">
                  <All />
                </TabPanel>
                <TabPanel h="inherit">
                  <Pending />
                </TabPanel>
                <TabPanel h="inherit">
                  <Approved />
                </TabPanel>
                <TabPanel h="inherit">
                  <Cancelled />
                </TabPanel>
                <TabPanel overflowY="scroll" h="inherit"></TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </Flex>
      </Box>
    </>
  );
};

export default Transactions;
