import React, { useEffect } from 'react';
import { Box } from '@chakra-ui/react';

const GoogleTranslate = () => {
  const googleTranslateElementInit = () => {
    if (window.google && window.google.translate) {
      new window.google.translate.TranslateElement(
        {
          pageLanguage: 'en',
          autoDisplay: false,
          gaTrack: false,
        },
        'google_translate_element'
      );
    }
  };

  useEffect(() => {
    let script = document.querySelector('#google-translate-script');
    if (!script) {
      script = document.createElement('script');
      script.id = 'google-translate-script';
      script.src =
        '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
      script.async = true;
      document.body.appendChild(script);
    }
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  return (
    <Box
      id="google_translate_element"
      pl={4}
      w="full"
      h={10}
      bgColor="#fe6600"
      alignContent="center"
    ></Box>
  );
};

export default GoogleTranslate;
