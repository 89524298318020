import React, {useState} from "react"
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import {
  SimpleGrid,
  Image,
  Flex,
  Text,
  Stack,
  Heading,
  Button,
  Box,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import images from '../../constants/images';

const About = () => {
  const navigate = useNavigate();

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const cancelRef = React.useRef();

  const onCloseConfirmDialog = () => {
    setIsConfirmDialogOpen(false);
    navigate("/investment-plan"); 
 
  };

  const onConfirmInvestment = () => {
    onCloseConfirmDialog();
    navigate("/register");    
  };







  const textStyle = {
    textAlign: 'justify',
    lineHeight: '1.6',
    fontSize: '16px'
  
  };

  return (
    <Box bgColor={'white'} py={12} mb={{ base: 4, md: 40 }}>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={{ base: 4, md: 10 }}>
        <Flex h={{ base: 'auto', md: 500 }} p={{ base: 2, md: 0 }}>
          <Image
            borderRadius={15}
            alt={'about image'}
            pl={{ base: 0, md: 2 }}
            src={images.about}
            objectFit={'cover'}
          />
        </Flex>
        <Stack spacing={4} h={{ base: 'auto', md: 500 }} p={{ base: 2, md: 4 }}>
          <Heading
           fontWeight={700}
           fontSize={{ base: '18px', md: '36px' }}
           lineHeight={{ base: '30px', md: '40px' }}
           mb={{ base: '10px', md: '15px' }}
          >
            About Us
          </Heading>
          <Text
            fontWeight={400}
            fontFamily={'sans-serif'}
            color={'#3B3B3B'}
            lineHeight={{ base: '24px', md: '28px' }}
            fontSize={{ base: '14px', md: 'sm' }}
            _first={textStyle}
            _notFirst={{ ...textStyle, mt: 4 }}
          >
            We provide the best investment profitability return with our carefully prepared and time-tested investment strategy.

            We specialize in cryptocurrency trading, with a team of highly qualified traders and analytical experts who can predict market movements with exceptional accuracy.

            CryptoHub Investment Limited has achieved a stable position in the financial market and gained the trust of investors from around the world. We aim to deliver a complete and professional service that meets the highest requirements of our clients, as evidenced by our growing list of long-standing clients.
          </Text>

          <Button
            onClick={() => setIsConfirmDialogOpen(true)}
            color={'white'}
            width={200}
            marginTop={30}
            height={'60px'}
            bgColor={'#fe6600'}
            cursor={'pointer'}
            fontSize={'md'}
            alignSelf={'center'}
            fontWeight={700}
            borderRadius={'15px'}
            display={'inline-block'}
          >
            Get Started
          </Button>
          <AlertDialog
        isOpen={isConfirmDialogOpen}
        leastDestructiveRef={cancelRef}
        onClose={onCloseConfirmDialog}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader alignItems={'center'} fontSize="lg" fontWeight="bold">
              Choose your Investment Plan
            </AlertDialogHeader>

            <AlertDialogBody>
              Do you want to proceed without selecting an investment plan?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button colorScheme="green" ref={cancelRef} onClick={onCloseConfirmDialog}>
                No
              </Button>
              <Button colorScheme="red" onClick={onConfirmInvestment} ml={3}>
                Yes
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
        </Stack>
      </SimpleGrid>
    </Box>
  );
};

export default About;
