import React from "react";

import { Badge, Flex, Box, Spacer, Text } from "@chakra-ui/react";


const AvailableBalanceCard = ({ financialData }) => {
  const availableBalance = financialData.availableBalance;


  return (
    <Box
      maxW="sm"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      shadow="md"
      p={4}
    >
        <>
          <Flex pb={2}>
            <Text>Available Balance</Text>
            <Spacer />
            <Badge borderRadius={5} p={2} colorScheme={"pink"} fontSize={"3xs"}>
              Daily
            </Badge>
          </Flex>
          <Text fontFamily={'1.953em'} lineHeight={1.5} fontWeight={500} textColor={'#3f414d'}>$ {availableBalance}
          </Text>
        </>
      
    </Box>
  );
};
export default AvailableBalanceCard;
