import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Avatar,
  Button,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { LogoutDialogue } from './LogoutDialogue';

const UserBadge = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const navigate = useNavigate();
 

  
  
  return (
    <>
      <Menu>
        <MenuButton
          bg="transparent"
          _hover={{ bg: 'brand.300', color: 'white' }}
          borderRadius="5px"
        >
          <Button bg="transparent" rightIcon={<ChevronDownIcon />}>
            <Avatar size="sm" mr="3" src={""}/>
            <Text></Text>
          </Button>
        </MenuButton>
        <MenuList w="full">
          <MenuItem onClick={() => navigate('/')}>Home</MenuItem>
          <MenuDivider />
          <MenuItem onClick={() => navigate('/client/settings')}>
            Update Profile
          </MenuItem>
          <MenuDivider />
          <MenuItem onClick={() => onOpen()}>LogOut</MenuItem>
        </MenuList>
      </Menu>
      <LogoutDialogue isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default UserBadge;