import landing from '../assets/Landing.jpg';
import about from '../assets/aboutimage.jpg';
import contact from '../assets/contact.jpg'
import home from '../assets/homeimage.jpg'
import logo from '../assets/logo2.png'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
landing,
about,
contact,
logo,
home,
};