import "../All/all.css";
export default function LargeWidget() {
  const Button = ({ type }) => {
    return <button className={"largeWidgetButton " + type}>{type}</button>;
  };
  return (
    <div className="largeWidget">
      <table className="largeWidgetTable">
        <tr className="largeWidgetRow">
          <th className="largeWidgetHeader">Customer Name</th>
          <th className="largeWidgetHeader">Email</th>
          <th className="largeWidgetHeader">Date</th>
          <th className="largeWidgetHeader">Amount</th>
          <th className="largeWidgetHeader">Phone</th>
          <th className="largeWidgetHeader">Investiment Plan</th>
          <th className="largeWidgetHeader">Status</th>
        </tr>
        <tr className="largeWidgetRow">
          <td className="largeWidgetUser">
            Big Show
          </td>
          <td className="largeWidgetEmail">big@gmail.com</td>
          <td className="largeWidgetDate">13 Oct 2021</td>
          <td className="largeWidgetAmount">$922.00</td>
          <td className="largeWidgetPhone">+1234567890</td>
          <td className="largeWidgetPlan">$922.00</td>
          <td className="largeWidgetStatus">
            <Button type="Declined" />
          </td>
        </tr>
        
      </table>
    </div>
  );
}
