import React from "react";
import { useNavigate } from "react-router-dom";
import { FaUsers } from "react-icons/fa";
import {GrTransaction  } from "react-icons/gr";
import {TbReportSearch  } from "react-icons/tb";
import { MdOutlineDashboard } from "react-icons/md";
//import {LogoutDialogue} from "../../PageComponents/LogoutDialogue"
import {
  HStack,
  Box,
  Text,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  IconButton,
} from "@chakra-ui/react";
import {
  FiMenu,
} from "react-icons/fi";

//import { LogoutDialogue } from "../../PageComponents/LogoutDialogue";
// eslint-disable-next-line no-lone-blocks
{/*
{/*
const handleRouteToDashboard = () => {
  const current_user = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : {};

  if (current_user !== {}) {
    if (current_user.session_status === "client") {
      return "/client/dashboard";
    } else if (current_user.session_status === "admin") {
      return "/admin/dashboard";
    } else {
      return "/login";
    }
  }
};
*/}




const SideBarItemData = [
  {
    id: 0,
    item_name: "Dashboard",
    icon: MdOutlineDashboard,
    route: `/admin/dashboard`,
  },
  {
    id: 1,
    item_name: "Users",
    icon: FaUsers,
    route: `/admin/users`,
  },
  {
    id: 2,
    item_name: "Transactions",
    icon: GrTransaction,
    route: `/admin/transactions`,
  },
  {
    id: 3,
    item_name: "Messages",
    icon: TbReportSearch,
    route: `/admin/messages`,
  },
];
const SideBarItem = (props) => {
  const navigate = useNavigate();

  const handleItemClick = () => {
    props.setCurrent(props.index);
    props.onClose(); 
    navigate(props.route);
  };

  return (
    <HStack
      _hover={{ color: "#fe6600" }}
      cursor="pointer"
      py="25px"
      pl="20px"
      className="sidebaritem"
      id={`sidebaritem-${props.index}`}
      onClick={handleItemClick}
    >
      <props.icon boxSize={30} pl="20px" color={'#fe6600'} />
      <Text>{props.item_name}</Text>
    </HStack>
  );
};

const Sidebar = () => {
  //const { userDetails } = useAuthState();
  const [current, setCurrent] = React.useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  //logic to set active sidebar item
  React.useEffect(() => {
    var sidebarItemsArray = document.getElementsByClassName("sidebaritem");
    var i;
    for (i = 0; i < sidebarItemsArray.length; i++) {
      sidebarItemsArray[i].className = sidebarItemsArray[i].className.replace(
        "active-sidebaritem",
        ""
      );
      if (sidebarItemsArray.length > 0) {
        sidebarItemsArray[i].classList.remove("active-sidebaritem");
      }
      const itemIndex = current;
      if (sidebarItemsArray[itemIndex] !== undefined)
        sidebarItemsArray[itemIndex].className += " active-sidebaritem";
    }
  }, [current]);
  return (
    <>
      {/* Responsive design for phone screens */}
      <Box display={["block", "none"]} textAlign='left' pl={2} >
        <IconButton
          ref={btnRef}
          icon={<FiMenu />}
          style={{ background: 'transparent', border: 'none', cursor: 'pointer' }}
          variant="outline"
          onClick={onOpen}
        />
      </Box>

      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Menu</DrawerHeader>
          <DrawerBody>

            {SideBarItemData.map((item, index) => (
              <SideBarItem
                key={item.id}
                item_name={item.item_name}
                icon={item.icon}
                route={item.route}
                index={index}
                setCurrent={setCurrent}
                onClose={onClose}
              />
            ))}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      {/* Non-responsive design for screens larger than phones */}
      <Box display={["none", "block"]} borderRightWidth={1} borderColor={'brand.200'}>
        <Box bg="white" w="15vw" minH="100vh" >
          {SideBarItemData.map((item, index) => (
            <SideBarItem
              key={item.id}
              item_name={item.item_name}
              icon={item.icon}
              route={item.route}
              index={index}
              setCurrent={setCurrent}
              onClose={onClose} 
            />
          ))}
        </Box>
      </Box>
  
    </>
  );
};
export default Sidebar;
