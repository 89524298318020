import React, {useState} from "react"
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";

import { Box, Button, Text, VStack, useBreakpointValue } from "@chakra-ui/react";
import images from "../../constants/images";
import { useNavigate } from "react-router-dom";

const Landing = () => {
  const navigate = useNavigate();
  const isSmallerScreen = useBreakpointValue({ base: true, lg: false });

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const cancelRef = React.useRef();

  const onCloseConfirmDialog = () => {
    setIsConfirmDialogOpen(false);
    navigate("/investment-plan"); 
 
  };

  const onConfirmInvestment = () => {
    onCloseConfirmDialog();
    navigate("/register");    
  };
  return (
    <Box
      opacity={0.8}
      w={"full"}
      h={isSmallerScreen ? "auto" : "90vh"}
      bgImage={images.home}
      backgroundSize={"cover"}
      backgroundPosition={"center"}
    >
      <VStack align={isSmallerScreen ? "center" : "flex-start"} p={isSmallerScreen ? 4 : 20}>
        <Text
          textColor={"#fe6600"}
          fontSize={isSmallerScreen ? "14px" : "40px"}
          align={"center"}
          fontWeight={600}
          letterSpacing={"0.8px"}
          pb={2}
        >
          CRYPTOHUB INVESTMENT
        </Text>
        <Text
          textColor={"white"}
          fontSize={isSmallerScreen ? "14px" : "30px"}
          textAlign={isSmallerScreen ? 'center' : 'start'}
          lineHeight={"1.1"}
          fontWeight={400}
          pb={5}
          maxW={"800px"}
        >
          Let Top Traders Do The Job For You
        </Text>
        <Button 
          color={"white"}
          width={isSmallerScreen ? "150px" : "200px"}
          height={"60px"}
          bgColor={"#fe6600"}
          cursor={"pointer"}
          fontSize={"16px"}
          fontWeight={"700"}
          borderRadius={"15px"}
          display={"inline-block"}
          onClick={() => setIsConfirmDialogOpen(true)}
          
          
        >
          Get Started
        </Button>
        <AlertDialog
        isOpen={isConfirmDialogOpen}
        leastDestructiveRef={cancelRef}
        onClose={onCloseConfirmDialog}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader alignItems={'center'} fontSize="lg" fontWeight="bold">
            Select Plan
            </AlertDialogHeader>

            <AlertDialogBody>
              Join with a plan?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button colorScheme="red"  onClick={onConfirmInvestment} >
                No
              </Button>
              <Button colorScheme="green"ref={cancelRef} onClick={onCloseConfirmDialog} ml={3}>
                Yes
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      </VStack>
    </Box>
  );
};

export default Landing;
