import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import "react-toastify/dist/ReactToastify.css";
import { ChakraProvider, theme } from '@chakra-ui/react';
import { Routes, Route, BrowserRouter } from "react-router-dom";
import AppContextProvider from "./context/state";

if (process.env.NODE_ENV === 'development') {
  // Display development warnings and logs
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <AppContextProvider>
          <Routes>
            <Route path="/*" element={<App />} />
          </Routes>
        </AppContextProvider>
      </BrowserRouter>
    </ChakraProvider>
  </React.StrictMode>
);
