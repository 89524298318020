import React, { useEffect, useState } from "react";
import { SimpleGrid, Box } from "@chakra-ui/react";
//import axios from "axios";
//import Exchanges from '../../components/Currency/Exchanges'
import API from "../../utils/api";
import AvailableBalanceCard from '../../Cards/AvailableBalanceCard'
import ActiveDepositcard from '../../Cards/ActiveDepositCard'
import LastWithdrawalCard from '../../Cards/LastWithdrawalCard'
import TotalDepositCard from '../../Cards/TotalDepositCard'
import InvestimentPlanCard from '../../Cards/InvestimentPlanCard'


const Dashboard = () => {

  const [financialData, setFinancialData] = useState({});
  const userId = JSON.parse(localStorage.getItem("userInfo"))
    ? JSON.parse(localStorage.getItem("userInfo"))._id
    : null;

  useEffect(() => {
    if (userId) {
      // Fetch financial data for the user
      const fetchFinancialData = async () => {
        try {
          const response = await API.getClientFinanceData(userId);
          if (response.status === 200) {
            setFinancialData(response.data);
          }
        } catch (error) {
          console.error("Error fetching financial data:", error);
        }
      };

      fetchFinancialData();
    }
  }, [userId]);

 
  return (
    <Box >
    <SimpleGrid columns={[1, 2, 3]} spacing="20px" m={4} >
    <AvailableBalanceCard financialData={financialData}/>
        <LastWithdrawalCard financialData={financialData} />
        <ActiveDepositcard financialData={financialData}/>
        <TotalDepositCard financialData={financialData}/>
        <InvestimentPlanCard financialData={financialData}/>
      </SimpleGrid>
    {/* <Exchanges /> */}
  </Box>
  )
}

export default Dashboard