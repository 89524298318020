import React, { useRef } from "react";
import { ChevronRightIcon, ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  HStack,
  Icon,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Stack,
  Center,
  Flex,
  Avatar,
  FormControl,
  FormLabel,
  Button,
  useEditableControls,
  ButtonGroup,
  IconButton,
  Editable,
  EditableInput,
  useToast,
  EditablePreview,
  AvatarBadge,
} from "@chakra-ui/react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
} from "@chakra-ui/react";

import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import { MdEdit } from "react-icons/md";
import { BiImageAdd } from "react-icons/bi";
import { useState } from "react";
import API from "../../utils/api";
import { useNavigate } from "react-router-dom";

function EditableControls() {
  const {
    isEditing,
    getSubmitButtonProps,
    getCancelButtonProps,
    getEditButtonProps,
  } = useEditableControls();

  return isEditing ? (
    <ButtonGroup justifyContent="center" size="sm">
      <IconButton icon={<CheckIcon />} {...getSubmitButtonProps()} />
      <IconButton icon={<CloseIcon />} {...getCancelButtonProps()} />
    </ButtonGroup>
  ) : (
    <Flex justifyContent="center">
      <IconButton icon={<MdEdit />} {...getEditButtonProps()} />
    </Flex>
  );
}

const buttonStyles = {
  mt: "3vh",
  mb: "2vh",
  type: "submit",
 
  color: "bold",
};

const accordionButtonStyles = {
  borderBottomWidth: "1px",
  borderColor: "neutral.200",
  p: "4",
};

const editableStyles = {
  borderWidth: "1px",
  borderColor: "gray.200",
  borderRadius: "5px",
  p: "2",
  isPreviewFocusable: false,
};

const Settings = () => {
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const navigate = useNavigate();
  const inputFile = useRef(null);
  const toast = useToast();

  const userId = JSON.parse(localStorage.getItem("userInfo"))
    ? JSON.parse(localStorage.getItem("userInfo"))._id
    : null;

  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => setIsOpen(false);
  const cancelRef = useRef();

  const handleDeleteAccount = () => {
    setIsOpen(true);
  };

  const handleDeleteAccountConfirmation = async () => {
    try {
      if (userId) {
        const response = await API.deleteUser(userId);
        if (response.status === 200) {
          toast({
            title: "Account deleted Successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "center",
          });
          navigate("/");
        } else {
          toast({
            title: "Account deletion failed, please try again later",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "center",
          });
        }
      }
    } catch (error) {
      console.error("An error occurred while processing your request.");
    }
  };

  

  const [username, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [newpassword, setNewpassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [investmentPlan, setInvestmentPlan] = useState("");

  const validateForm = () => {
    if (
      username.trim() !== "" &&
      email.trim() !== "" &&
      newpassword.trim() !== "" &&
      confirmNewPassword.trim() !== "" &&
      newpassword === confirmNewPassword
    ) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isSubmitDisabled) {
      // Handle validation errors, e.g., display a toast message
      toast({
        title: "Please fill in all fields and ensure passwords match",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
      return;
    }
 
    const formData = {
      username: username,
      email: email,
      newpassword: newpassword,
      confirmNewPassword: confirmNewPassword,
    };
 
    try {
      if (userId) {
        const response = await API.updateprofile(userId,formData);
        if (response.status === 200) {
          toast({
            title: "Account details updated Successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "center",
          });
          navigate("/");
        } else {
          toast({
            title: "Account details failed to update, please try again later",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "center",
          });
        }
      }
    } catch (error) {
      console.error("An error occurred while processing your request.");
    }
  
}
const handleUsernameBlur = () => {
  validateForm();
};

const handleEmailBlur = () => {
  validateForm();
};

const handlePasswordBlur = () => {
  validateForm();
};

const handleConfirmPasswordBlur = () => {
  validateForm();
};

const handleSubmitPlan = async (e) => {
  e.preventDefault();


  const formData = {
    investmentPlan: investmentPlan,
  
  };

  try {
    if (userId) {
      const response = await API.updatePlan(userId,formData);
      if (response.status === 200) {
        toast({
          title: "Plan Updated Successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "center",
        });
        navigate("/client/dashboard")
      } else {
        toast({
          title: "Plan failed to update, please try again later",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "center",
        });
      }
    }
  } catch (error) {
    console.error("An error occurred while processing your request.");
  }

}

  return (
    <Stack spacing={0} overflowY="scroll" h="100%">
      <input
        type="file"
        id="file"
        ref={inputFile}
        style={{ display: "none" }}
      />

      <Center pb={5} width="inherit">
        <HStack
          width="90%"
          justifyContent="space-between"
          alignSelf="center"
          mt="4"
        >
          <Text>Edit User Information</Text>

          <Text>
            Account <Icon as={ChevronRightIcon} /> <Icon as={ChevronDownIcon} />
          </Text>
        </HStack>
      </Center>

      {/* Accordion */}
      <Center>
        <Stack width={{base:"100%", md:"70%"}} ml={{base:2, md:0}}>
          <Accordion
            defaultIndex={[0]}
            allowMultiple
            bg="white"
            borderColor="white"
            borderRadius="10px"
          >
            <AccordionItem _expanded={{ width: "50vw" }}>
              <h2>
                <AccordionButton
                  {...accordionButtonStyles}
                  borderTopRadius="10px"
                >
                  <Box flex="1" textAlign="left">
                    User Profile
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel p={30} width="inherit">
                <Stack>
                  <Avatar
                    alignSelf="center"
                    size="2xl"
                    src={""}
                    borderWidth="3px"
                    borderColor="neutral.100"
                  >
                    <AvatarBadge
                      as={IconButton}
                      size="sm"
                      rounded="full"
                      colorScheme="blue"
                      aria-label="remove Image"
                      icon={<BiImageAdd color="#fff" />}
                    />
                  </Avatar>

                  <form id="login-form">
                    <FormControl id="username" mt="5vh" isRequired>
                      <FormLabel>Usernme</FormLabel>
                      <Editable
                        defaultValue="Enter your username"
                        id="username"
                        {...editableStyles}
                      >
                        <HStack justifyContent="space-between">
                          <EditablePreview />
                          <EditableInput  value={username}
                          onBlur={handleUsernameBlur}
                  onChange={(e) => setUserName(e.target.value)}  />
                          <EditableControls />
                        </HStack>
                      </Editable>
                    </FormControl>
                    <FormControl id="email" mt="3vh">
                      <FormLabel>Email Address</FormLabel>
                      <Editable
                        id="email"
                        placeholder="Enter your email address"
                        {...editableStyles}
                      >
                        <HStack justifyContent="space-between">
                          <EditablePreview />
                          <EditableInput  value={email}
                           onBlur={handleEmailBlur}
                  onChange={(e) => setEmail(e.target.value)} />
                          <EditableControls />
                        </HStack>
                      </Editable>
                    </FormControl>
                   
                    <FormControl id="password" mt="5vh" isRequired>
                      <FormLabel>Password</FormLabel>
                      <Editable
                        defaultValue="Password"
                        id="Password"
                        {...editableStyles}
                      >
                        <HStack justifyContent="space-between">
                          <EditablePreview />
                          <EditableInput  value={newpassword}
                           onBlur={handlePasswordBlur}
                  onChange={(e) => setNewpassword(e.target.value)}/>
                          <EditableControls />
                        </HStack>
                      </Editable>
                    </FormControl>
                    <FormControl id="repeat password" mt="5vh" isRequired>
                      <FormLabel>Repeat Password</FormLabel>
                      <Editable
                        defaultValue="Repeat Password"
                        id="Repeat Password"
                        {...editableStyles}
                      >
                        <HStack justifyContent="space-between">
                          <EditablePreview />
                          <EditableInput  value={confirmNewPassword}
                           onBlur={handleConfirmPasswordBlur}
                  onChange={(e) => setConfirmNewPassword(e.target.value)} />
                          <EditableControls />
                        </HStack>
                      </Editable>
                    </FormControl>
                    <Button
                      {...buttonStyles}
                      _focus={{ outline: "none" }}
                      _active={{ outline: "none" }}
                      width={{base:'90%', md:'30%'}}
                      bgColor={"#fe6600"}
                      onClick={handleSubmit}
                      isDisabled={isSubmitDisabled}
                    >
                      Update Account
                    </Button>
                  </form>
                </Stack>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h2>
                <AccordionButton
                  {...accordionButtonStyles}
                  _expanded={{
                    borderTopWidth: "0px",
                    borderBottomWidth: "1px",
                  }}
                >
                  <Box flex="1" textAlign="left">
                    Billing Information
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} p={30}>
                <Text>
                  Fill in the bank information into which you would want your
                  transaction return and bonuses
                </Text>
                <FormControl id="Investment Plan" mt="3vh">
                  <FormLabel>Investment Plan </FormLabel>
                  <Editable id="plan" placeholder="Plan" {...editableStyles}>
                    <HStack justifyContent="space-between">
                      <EditablePreview />
                      <EditableInput value={investmentPlan}
                  onChange={(e) => setInvestmentPlan(e.target.value)}/>
                      <EditableControls />
                    </HStack>
                  </Editable>
                </FormControl>

                <Button
                  {...buttonStyles}
                  _focus={{ outline: "none" }}
                  _active={{ outline: "none" }}
                  width={{base:'90%', md:'30%'}}
                  bgColor={"#fe6600"}
                  onClick={handleSubmitPlan}
                >
                  Update Investment Plan
                </Button>
              </AccordionPanel>
            </AccordionItem>
          

            <AccordionItem>
              <h2>
                <AccordionButton
                  {...accordionButtonStyles}
                  _expanded={{
                    borderTopWidth: "0px",
                    borderBottomWidth: "1px",
                  }}
                >
                  <Box flex="1" textAlign="left">
                    Advanced Settings
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} p={30}>
                <Stack textAlign="left">
                  <Text fontWeight="bold">Delete Account</Text>
                  <Text>
                    This means that all your information will be lost and
                    history of appointments. Your account will also be deleted
                    permanently. Please be sure you would like tio do this
                    before you proceed
                  </Text>
                </Stack>
                <Box alignItems="right">
                  <Button
                    {...buttonStyles}
                    _focus={{ outline: "none" }}
                    _active={{ outline: "none" }}
                    width={{base:'90%', md:'30%'}}
                    bgColor={"#fe6600"}
                    onClick={handleDeleteAccount}
                  >
                    Delete Account
                  </Button>
                  {/* Delete Account Confirmation Dialog */}
                  <AlertDialog
                    isOpen={isOpen}
                    leastDestructiveRef={cancelRef}
                    onClose={onClose}
                  >
                    <AlertDialogOverlay>
                      <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                          Delete Account
                        </AlertDialogHeader>
                        <AlertDialogCloseButton />
                        <AlertDialogBody>
                          Are you sure you want to delete your account? This
                          action cannot be undone.
                        </AlertDialogBody>
                        <AlertDialogFooter>
                          <Button
                            colorScheme="green"
                            ref={cancelRef}
                            onClick={onClose}
                          >
                            No
                          </Button>
                          <Button
                            ml={3}
                            colorScheme="red"
                            onClick={handleDeleteAccountConfirmation}
                          >
                            Yes
                          </Button>
                        </AlertDialogFooter>
                      </AlertDialogContent>
                    </AlertDialogOverlay>
                  </AlertDialog>
                </Box>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Stack>
      </Center>
    </Stack>
  );
};

export default Settings;
