import React, {useState} from 'react'
import {
    Button,
    FormControl,
    useToast,
    Flex,
    FormErrorMessage,
    Heading,
    Input,
    Stack,
    Text,
    useColorModeValue,
  } from '@chakra-ui/react';
import API from "../../../utils/api";


  
  
  const ForgotPassword=()=> {
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const toast = useToast();

   const handleSubmit = async (e) => {
    e.preventDefault();
    setEmailError("");

    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    if (!email.match(emailRegex)) {
      setEmailError("Invalid email address");
      return;
    }

    const formData = {
      email: email,
    };

    try {
      const response = await API.confirmEmail(formData);
      if (response.status === 200)  {
        setEmail("");
        toast({
          title: "Congratulations you are one of us",
          status: "success",
          duration: 3000,
          description: "Kindly check your email for password reset link",
          isClosable: true,
          position: "top",
        });
      }
        else {
          setEmail('');
          toast({
            title: 'User not found',
            status: 'error',
            description: 'No user with such email exists',
            duration: 3000,
            isClosable: true,
            position: 'top',
          });
        }
      } catch (error) {
        setEmail('');
        toast({
          title: 'Error',
          status: 'error',
          description: 'An error occurred. Please try again later.',
          duration: 3000,
          isClosable: true,
          position: 'top',
        });
      }
    };
    return (
      <Flex
        minH={'100vh'}
        align={'center'}
        justify={'center'}
        bg={useColorModeValue('gray.50', 'gray.800')}>
        <Stack
          spacing={4}
          w={'full'}
          maxW={'md'}
          bg={useColorModeValue('white', 'gray.700')}
          rounded={'xl'}
          boxShadow={'lg'}
          p={6}
          my={12}>
          <Heading lineHeight={1.1} fontSize={{ base: 'sm', md: 'md' }}>
            Confirm your password?
          </Heading>
          <Text
            fontSize={{ base: 'sm', sm: 'md' }}
            color={useColorModeValue('gray.800', 'gray.400')}>
            Enter your registered email address to receive password reset link
          </Text>
          <FormControl id="email" isInvalid={emailError}>
            <Input
              placeholder="email@example.com"
              _placeholder={{ color: 'gray.500' }}
              type="email"
              value={email}
                  onChange={(e) => setEmail(e.target.value)}
            />
             <FormErrorMessage>{emailError}</FormErrorMessage>
          </FormControl>
          
          <Stack spacing={6}>
            <Button
                bgColor={"#fe6600"}
              color={'white'}
              onClick={handleSubmit}
              
             
              >
            
              Check
            </Button>
          </Stack>
        </Stack>
      </Flex>
    );
  }
  export default ForgotPassword;